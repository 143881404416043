/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, PriceWrap, Button } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader />

        <Column className="pb--50 pl--0 pt--80" name={"uvod"}>
          
          <ColumnWrap className="column__flex el--1 pb--40 pl--40 pr--40 pt--60 flex--center" anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper className="--center mt--10 pl--0 pr--0">
              
              <Title className="title-box fs--62 w--300 ls--002 lh--14" style={{"maxWidth":1082}} content={"Služby"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--30" anim={null} name={"gpv2jicmzpc"} animS={null}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2" anim={"2"} animS={"2"} style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--style6 subtitle-box--left lh--16" content={"Zakázkova výroba"}>
              </Subtitle>

              <Text className="text-box mt--30" style={{"maxWidth":898}} content={"Klikněte a začněte psát text, tento prostor je pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1 mb--40 mt--40" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2" anim={"2"} animS={"2"} style={{"maxWidth":""}}>
              
              <PriceWrap className="pl--02 pr--02" style={{"maxWidth":""}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Sit amet lorem conesctetur"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"od 25 000,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--02 pr--02" style={{"maxWidth":""}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Sit amet lorem conesctetur"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"od 25 000,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--02 pr--02" style={{"maxWidth":""}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Sit amet lorem conesctetur"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"od 25 000,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--02 pr--02" style={{"maxWidth":""}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Sit amet lorem conesctetur"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"od 25 000,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--02 pr--02" style={{"maxWidth":""}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Sit amet lorem conesctetur"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"od 25 000,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"phvo0j08j3s"} style={{"backgroundColor":"var(--color-custom-2)"}} border={null} layout={"l24"}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right mt--0" content={"Posaďme se"}>
              </Subtitle>

              <Button className="btn-box btn-box--left mt--0" href={"/hr/kontakt"} content={"Poptávka"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pl--0 pt--50" name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--3 pb--40 pt--40 flex--bottom" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Merbau"}>
              </Title>

              <Text className="text-box text-box--left fs--16" style={{"maxWidth":351}} content={"Vyrábíme dřevěný nábytek v centru Brna z těch nejkvalitnějších — odpovědných — přírodních zdrojů."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--left" content={"Holandská 854/1"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"Brno, Brno — Město"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"+420 797 811 2X0"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--hvr2 btn-box--sbtn1 btn-box--cColor2 btn-box--filling2 fs--18" href={"#uvod"} content={"<span style=\"color: var(--color-supplementary);\">Zpět nahoru</span>"}>
              </Button>

              <Text className="text-box text-box--right mt--0" content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}